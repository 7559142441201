<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess" />
 <div>
  <PbxHeader
   v-model:search="search"
   pbxMenuType="queueDetail"
   :periodSelector="period"
   :parseDateRange="parseDateRange"
   @select-hostname="selectHostname = $event"
   :selectedQueue="selectedQueue"
   :parseTimeRange="parseTimeRange"
   :timeSelected="selectedtime"
   :buttonSelected="buttonSelected"
   @activeBanner="(activeBanner = true), (key = !key)"
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   urlType="queue" />
  <div class="mx-6">
   <div class="md:pl-0 pl-10 pr- pt-5">
    <PbxDashboardSelector
     :key="pbxElements"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     @action="
      $emit('action', $event),
       (selectedElement = $event),
       separateQueueExtensionData()
     "
     :selectHostname="selectHostname"
     :urlType="urlType"
     @queueName="selectedQueue = $event"
     :buttonSelected="buttonSelected"
     :elements="selectedElement"
     @timeSelector="selectedtime = $event"
     @concatenate="concatenateData = $event"
     :groupDatesQueueReport="groupData" />
   </div>
   <!-- <div v-if="showInfoText" class="relative bg-blue-200 rounded-lg px-2 py-4 my-2">
    <div class="absolute right-2 top-2">
     <XIcon
      @click="showInfoText = false"
      class="h-4 w-4 text-blue-500 hover:text-blue-600 cursor-pointer"
      aria-hidden="true"
     />
    </div>
    <div class="flex gap-2 items-start text-left">
     <div>
      <InformationCircleIcon class="h-6 w-6 text-blue-400" aria-hidden="true" />
     </div>
     <div class="space-y-1">
      <p class="text-blue-700 text-sm font-medium">
       {{ $t("expertStatsDurationsInfoText") }}
      </p>
      <p class="text-blue-700 text-sm font-medium">
       {{ $t("expertStatsDurationsInfoSubTextOne") }}
      </p>
      <p class="text-blue-700 text-sm font-medium">
       {{ $t("expertStatsDurationsInfoSubTextTwo") }}
      </p>
     </div>
    </div>
   </div> -->
  </div>

  <div
   class="flex flex-col"
   v-if="queuesOnlyStats ? queueData.length > 0 : extensionData.length > 0">
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-6">
     <div class="flex">
      <div class="flex items-center gap-6 absolute left-25 top-54">
       <!-- <div class="h-10 flex rounded-md shadow-sm">
        <button type="button" class="relative inline-flex gap-1 items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
         {{ $t(selectedRangeTypeText) }}
        </button>
        <Menu as="div" class="relative -ml-px block">
         <MenuButton class="h-10 relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
          <span class="sr-only">Open options</span>
          <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
         </MenuButton>
         <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
         >
          <MenuItems class="absolute -right-20 z-20 mt-2 -mr- w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
           <div class="py-1">
            <MenuItem v-for="item in durationMenuItems" :key="item.name" v-slot="{ active }">
             <button @click="(selectedRangeType = item.name), (selectedRangeTypeText = item.text)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm text-medium w-full text-left']">
              {{ $t(item.text) }}
             </button>
            </MenuItem>
           </div>
          </MenuItems>
         </transition>
        </Menu>
       </div> -->
       <div class="rounded-md py-5">
        <p class="text-sm text-gray-600 font-bold">{{ $t("timeRange") }}</p>
        <div class="flex items-center gap-2">
         <input
          v-model="timeRange"
          v-if="selectedRangeType === 'waiting'"
          type="range"
          id="vol"
          name="vol"
          min="5"
          max="100"
          step="5" />
         <input
          v-model="timeRange"
          v-else
          type="range"
          id="vol"
          name="vol"
          min="40"
          max="140"
          step="20" />
         <span class="text-xs text-gray-500 font-medium">
          {{ timeRange }}(s)
         </span>
        </div>
       </div>
       <div class="rounded-md py-5">
        <p class="text-sm text-gray-600 font-bold">{{ $t("timeGap") }}</p>
        <div class="flex items-center gap-2">
         <input
          v-model="timeGap"
          v-if="selectedRangeType === 'waiting'"
          type="range"
          id="vol"
          name="vol"
          min="5"
          max="20"
          step="5" />
         <input
          v-model="timeGap"
          v-else
          type="range"
          id="vol"
          name="vol"
          min="10"
          max="40"
          step="10" />
         <span class="text-xs text-gray-500 font-medium">
          {{ timeGap }}(s)
         </span>
        </div>
       </div>

       <div class="rounded-md py-5">
        <p class="text-sm text-gray-600 font-bold">{{ $t("columns") }}</p>
        <div class="flex items-center gap-2">
         <input
          v-model="numberOfColumns"
          type="range"
          id="vol"
          name="vol"
          min="1"
          max="5"
          step="1" />
         <span class="text-xs text-gray-500 font-medium">
          {{ numberOfColumns }}
         </span>
        </div>
       </div>
      </div>
      <div class="flex ml-6 gap-2 switch-group-no-info">
       <SwitchGroup as="div" class="flex items-center gap-1">
        <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
         <span class="text-sm font-medium text-gray-900">{{
          $t("showQueuesOnlyStats")
         }}</span>
         <!-- <span class="text-sm text-gray-500">20s</span> -->
        </SwitchLabel>
        <Switch
         v-model="queuesOnlyStats"
         :class="[
          queuesOnlyStats ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
         ]">
         <span
          aria-hidden="true"
          :class="[
           queuesOnlyStats ? 'translate-x-5' : 'translate-x-0',
           'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          ]" />
        </Switch>
        <SwitchLabel as="span" class="flex gap-1">
         <!-- <span class="text-sm font-medium text-gray-900">{{$t('displayAnswerTimeIn')}}</span> -->
         <!-- <span class="text-sm text-gray-500">30s</span> -->
        </SwitchLabel>
       </SwitchGroup>
       <SwitchGroup as="div" class="flex items-center gap-1">
        <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
         <span class="text-sm font-medium text-gray-900">{{
          $t("groupData")
         }}</span>
         <!-- <span class="text-sm text-gray-500">20s</span> -->
        </SwitchLabel>
        <Switch
         v-model="groupData"
         :class="[
          groupData ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
         ]">
         <span
          aria-hidden="true"
          :class="[
           groupData ? 'translate-x-5' : 'translate-x-0',
           'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          ]" />
        </Switch>
        <SwitchLabel as="span" class="flex gap-1">
         <!-- <span class="text-sm font-medium text-gray-900">{{$t('displayAnswerTimeIn')}}</span> -->
         <!-- <span class="text-sm text-gray-500">30s</span> -->
        </SwitchLabel>
       </SwitchGroup>
      </div>
     </div>

     <div class="overflow-hidden border-b border-gray-200 rounded-md pt-20">
      <QueueFullReportTable
       :tableData="queuesOnlyStats ? filteredQueueData : filteredExtensionData"
       :header="queuesOnlyStats ? queueHeader : extensionHeader"
       :body="queuesOnlyStats ? queueDataKey : extensionDataKey"
       :selectedtime="selectedtime"
       :queuesArray="queuesArray"
       :queuesNameArray="queuesNameArray"
       :extensionsArray="extensionsArray"
       :extensionsNameArray="extensionsNameArray"
       :extensionsNameNumberArray="extensionsNameNumberArray"
       :queuesNameNumberArray="queuesNameNumberArray"
       v-model:searchQueue="searchQueue"
       v-model:searchQueueName="searchQueueName"
       v-model:searchExtension="searchExtension"
       v-model:searchExtensionName="searchExtensionName"
       :period="period"
       :parseDateRange="parseDateRange"
       :queuesOnlyStats="queuesOnlyStats"
       :timeRange="timeRange"
       :timeGap="timeGap"
       :numberOfColumns="numberOfColumns"
       :selectedRangeType="selectedRangeType"
       :concatenateData="concatenateData"
       @sort="sort($event)" />
     </div>
    </div>
   </div>
  </div>
  <div v-else class="pt-20">
   <div class="text-center">
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor">
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
    </svg>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
     {{
      this.pbxElements && this.pbxElements.length > 0
       ? $t("noDataFoundTitle")
       : $t("selectElementFromList")
     }}
    </h3>
   </div>
  </div>
 </div>
 <!-- <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 /> -->
</template>

<script>
import Tooltip from "../../components/Collect/formTools/Tooltip.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SuccessBanner from "../../components/SuccessBanner.vue";
import TablePagination from "../../components/TablePagination.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import QueueFullReportTable from "../../components/PBX/QueueFullReportTable.vue";
import {
 Switch,
 SwitchGroup,
 SwitchLabel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { ChevronDownIcon, XIcon } from "@heroicons/vue/outline";
import {
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
 InformationCircleIcon,
} from "@heroicons/vue/solid";

const durationMenuItems = [
 { name: "waiting", href: "#", text: "Durées d'attente" },
 //  { name: "talking", href: "#", text: "Durées d'appel" },
];

const keyType = {
 0: "extension",
 4: "queue",
};

const actionType = {
 answered: "answered",
 "1c": "answered_calls",
 transferred: "transferred",
 //  terminated_by_dst: "declined_solicitations",
 //  terminated_by_dst: "declined_calls",
 disuaded: "declined_solicitations",
 abandoned: "abandoned",
 ping: "ping",
 pong: "pong",
 calls: "calls",
 extension_pings: "extension_pings",
 solicited: "solicited",
};

const extensionHeader = [
 { text: "date", info: "", hasInfo: false },
 //  { text: "time", info: "", hasInfo: false },
 { text: "queue", info: "", hasInfo: false },
 { text: "extension", info: "", hasInfo: false },
 {
  text: "calls",
  info: "Nombre d’appels uniques reçus par la ressouce.",
  hasInfo: true,
  key: "calls",
 },
 {
  text: "solicited",
  info:
   "Nombre de sollications reçues par la ressource. Au cours d’un appel unique, la même ressource peut être sollicitée à plusieurs reprises.",
  hasInfo: true,
 },
 {
  text: "declined",
  info:
   "Nombre de sollications rejetées par la ressource. Il s’agit d’une dissuasion dans le cas de la file d’appel et d’une non réponse dans le cas de l’extension.",
  hasInfo: true,
 },
 {
  text: "abandoned",
  info: "Appels abandonnés par l’appelant.",
  hasInfo: true,
 },
 { text: "answered", info: "", hasInfo: false, key: "answered" },
 {
  text: "transferred",
  info:
   "Appels répondus, puis transférés vers une autre ressouce (file d’attente ou extension).",
  hasInfo: true,
 },
 {
  text: "answered_percentage",
  info:
   "Ce pourcentage représente le rapport du nombre des appels répondus par la ressource au nombre total des appels présentés.",
  hasInfo: true,
  key: "answered_percentage",
 },
 { text: "talking_duration", info: "", hasInfo: false },
 { text: "waiting_duration", info: "", hasInfo: false },
 {
  text: "ranges",
  info:
   "Nombre d’appels répondus dans le délai considéré. Seuls les appels effectivement répondus par la ressource sont pris en compte.",
  hasInfo: true,
 },
];

const queueHeader = [
 { text: "date", info: "", hasInfo: false },
 //  { text: "time", info: "", hasInfo: false },
 { text: "queue", info: "", hasInfo: false },
 {
  text: "calls",
  info: "Nombre d’appels uniques reçus par la ressouce.",
  hasInfo: true,
  key: "calls",
 },
 {
  text: "solicited",
  info:
   "Nombre de sollications reçues par la ressource. Au cours d’un appel unique, la même ressource peut être sollicitée à plusieurs reprises.",
  hasInfo: true,
 },
 {
  text: "declined",
  info:
   "Nombre de sollications rejetées par la ressource. Il s’agit d’une dissuasion dans le cas de la file d’appel et d’une non réponse dans le cas de l’extension.",
  hasInfo: true,
 },
 {
  text: "abandoned",
  info: "Appels abandonnés par l’appelant.",
  hasInfo: true,
 },
 {
  text: "answered",
  info: "",
  hasInfo: false,

  key: "answered",
 },
 {
  text: "transferred",
  info:
   "Appels répondus, puis transférés vers une autre ressouce (file d’attente ou extension).",
  hasInfo: true,
 },
 {
  text: "answered_percentage",
  info:
   "Ce pourcentage représente le rapport du nombre des appels répondus par la ressource au nombre total des appels présentés.",
  hasInfo: true,
  key: "answered_percentage",
 },
 { text: "talking_duration", info: "", hasInfo: false },
 { text: "waiting_duration", info: "", hasInfo: false },
 {
  text: "ranges",
  info:
   "Nombre d’appels répondus dans le délai considéré. Seuls les appels effectivement répondus par la ressource sont pris en compte.",
  hasInfo: true,
 },
];

const extensionDataKey = [
 "formatedDate",
 "queueNameNumber",
 "elementNameNumber",
 "calls_ext",
 "solicited",
 "declined_solicitations",
 "abandoned",
 "answered",
 "transferred",
 "answered_percentage_ext",
 "talking_duration",
 "waiting_duration",
 "ranges",
];

const queueDataKey = [
 "formatedDate",
 "queueNameNumber",
 "calls",
 "solicited",
 "declined_solicitations",
 "abandoned",
 "answered",
 "transferred",
 "answered_percentage_ext",
 "talking_duration",
 "waiting_duration",
 "ranges",
];

export default {
 props: ["urlType"],
 components: {
  Loading,
  PbxHeader,
  PbxDashboardSelector,
  TablePagination,
  SuccessBanner,
  QueueFullReportTable,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  ChevronDownIcon,
  Tooltip,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  InformationCircleIcon,
  XIcon,
 },
 data() {
  return {
   showInfoText: false,
   currentSortDir: "asc",
   currentSort: "",
   durationMenuItems,
   selectedRangeType: "waiting",
   selectedRangeTypeText: "Durées d'attente",
   timeRange: 20,
   timeGap: 10,
   numberOfColumns: 4,
   groupData: true,
   searchQueue: null,
   searchQueueName: null,
   searchExtension: null,
   searchExtensionName: null,
   queuesNameArray: [],
   queuesArray: [],
   extensionsNameArray: [],
   extensionsNameNumberArray: [],
   queuesNameNumberArray: [],
   extensionsArray: [],
   queueHeader,
   queueDataKey,
   extensionHeader,
   extensionDataKey,
   queuesOnlyStats: false,
   keyType,
   actionType,
   data: {},
   selectedtime: undefined,
   fullPage: true,
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   queueData: [],
   extensionData: [],
   textMonth: "",
   buttonSelected: "month",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   textMonth: "",
   buttonSelected: "month",
   settedGroupTableData: [],
   settedUngroupedTableData: [],
   queueDataConsolidated: [],
   concatenateData: false,
  };
 },
 methods: {
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getData(sentPeriod) {
   this.$emit("setLoading", true);
   let period = "";
   let start = "";
   let end = "";
   if (sentPeriod) {
    period = sentPeriod;
   } else {
    period = this.period;
   }
   if (period.length == 6) {
    start = this.parseDateDayMonthYearHifen(
     this.getFirstDayOfMonth(period.slice(0, 4), period.slice(-2))
    );
    end = this.parseDateDayMonthYearHifen(
     this.getLastDayOfMonth(period.slice(0, 4), period.slice(-2))
    );
   }
   if (period.length == 8) {
    start = this.parseDateDayMonthYearHifen(
     new Date(
      period.slice(0, 4),
      parseInt(period.slice(4, 6)) - 1,
      parseInt(period.slice(-2))
     )
    );
    end = start;
   }
   if (period.includes("-")) {
    start = this.parseDateDayMonthYearHifen(
     new Date(
      period.split("-")[0].slice(0, 4),
      parseInt(period.split("-")[0].slice(4, 6)) - 1,
      parseInt(period.split("-")[0].slice(-2))
     )
    );
    end = this.parseDateDayMonthYearHifen(
     new Date(
      period.split("-")[1].slice(0, 4),
      parseInt(period.split("-")[1].slice(4, 6)) - 1,
      parseInt(period.split("-")[1].slice(-2))
     )
    );
   }
   let arrayOfElements = [];
   let stringOfElements = "";
   if (this.pbxElements && this.pbxElements.length > 0) {
    this.pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);

    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/queue-report?start=${start}&end=${end}&dns=${stringOfElements}`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     console.log(
      "file: ExpertStatisticsFullQueueReport.vue:227 ~ getData ~ res.data:",
      res.data
     );
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async setTableData(data) {
   if (this.pbxElements && this.pbxElements.length > 0) {
    this.$emit("setLoading", true);
    let rawDataArray = [];
    let dataArray = [];
    let dataObj = {};
    let queueReportData = data;
    let queueReportDataUngrouped = queueReportData["daily"];
    if (this.selectedtime && this.selectedtime.length > 1) {
     queueReportDataUngrouped = queueReportData["all"];
    }

    let pbxMapData =
     this.pbxMap && Object.keys(this.pbxMap).length > 0
      ? this.pbxMap
      : this.getPbxMap();

    let queueDataKeysUngrouped = Object.keys(queueReportDataUngrouped);
    queueDataKeysUngrouped.forEach((key, index) => {
     let splitedKey = key.split(":");
     let queueNumber = splitedKey[2];
     let typeInfo = splitedKey[3];
     let elementNumber = splitedKey[4];
     let dataPeriod =
      splitedKey[0].length == 12 ? splitedKey[0] : splitedKey[0].slice(0, 8);
     let dataTime = splitedKey[0].length == 12 ? splitedKey[0].slice(-4) : null;
     let dataAction = splitedKey[5];
     let actionType =
      dataAction === "actions"
       ? this.actionType[splitedKey[6]]
       : dataAction === "duration" && !splitedKey[6].includes("_")
       ? [splitedKey[6]] + "_" + "duration"
       : dataAction === "duration" && splitedKey[6].includes("_")
       ? [splitedKey[6]]
       : null;
     dataObj = {};
     dataObj["queue"] = queueNumber;
     dataObj["queueName"] =
      pbxMapData &&
      pbxMapData["call_queues"] &&
      pbxMapData["call_queues"][queueNumber]
       ? pbxMapData["call_queues"][queueNumber]["name"]
       : null;
     dataObj["element"] = elementNumber;
     dataObj["elementName"] =
      typeInfo == 0 &&
      pbxMapData &&
      pbxMapData["extensions"] &&
      pbxMapData["extensions"][elementNumber]
       ? pbxMapData["extensions"][elementNumber]
       : null;
     dataObj["queueNameNumber"] =
      dataObj["queue"] +
      "-" +
      (dataObj["queueName"] ? dataObj["queueName"] : "");
     dataObj["elementNameNumber"] =
      dataObj["element"] +
      "-" +
      (dataObj["elementName"] ? dataObj["elementName"] : "");
     dataObj["date"] = dataPeriod;
     dataObj["time"] = dataTime;
     dataObj["formatedDate"] = this.parseDateRange(dataPeriod.slice(0, 8));
     dataObj["keyType"] = typeInfo;
     let action =
      dataAction === "actions" || dataAction === "duration"
       ? actionType
       : dataAction;
     dataObj[action] = queueReportDataUngrouped[key];
     dataObj[`${action}`] =
      dataAction === "actions"
       ? queueReportDataUngrouped[key]
       : queueReportDataUngrouped[key];
     if (this.selectedtime && this.selectedtime.length > 1) {
      let firsQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[0].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[0].slice(-2);
      let lastQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[1].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[1].slice(-2);
      if (dataTime) {
       if (
        parseInt(firsQuarterHour) <= parseInt(dataTime) &&
        parseInt(dataTime) <= parseInt(lastQuarterHour)
       ) {
        rawDataArray.push(dataObj);
       }
      }
     } else {
      if (
       action &&
       dataPeriod == splitedKey[0].slice(0, 8) &&
       !splitedKey[0].includes("w")
      ) {
       rawDataArray.push(dataObj);
      }
     }
    });
    let elements = [];
    let elementsName = [];
    let elementsNameNumber = [];
    let dates = [];
    let queues = [];
    let queuesName = [];
    let queuesNameNumber = [];
    rawDataArray.forEach(el => {
     if (el.element) {
      elements.push(el.element);
     }
     if (el.date) {
      dates.push(el.date);
     }
     if (el.queue) {
      queues.push(el.queue);
     }
     if (el.queueName) {
      queuesName.push(el.queueName);
     }
     if (el.elementName && el.keyType == 0) {
      elementsName.push(el.elementName);
     }
     if (el.element && el.elementNameNumber && el.keyType == 0) {
      elementsNameNumber.push(el.elementNameNumber);
     }
     if (el.queueNameNumber) {
      queuesNameNumber.push(el.queueNameNumber);
     }
    });
    let uniqElements = [...new Set(elements)];
    let uniqElementsName = [...new Set(elementsName)];
    let uniqElementsNameNumber = [...new Set(elementsNameNumber)];
    let uniqDates = [...new Set(dates)];
    let uniqQueues = [...new Set(queues)];
    let uniqQueuesName = [...new Set(queuesName)];
    let uniqQueuesNameNumber = [...new Set(queuesNameNumber)];

    this.queuesArray = uniqQueues;
    this.queuesNameArray = uniqQueuesName;
    this.queuesNameNumberArray = uniqQueuesNameNumber;
    this.extensionsArray = uniqElements;
    this.extensionsNameArray = uniqElementsName;
    this.extensionsNameNumberArray = uniqElementsNameNumber;

    uniqElements.forEach(el => {
     let filteredData = rawDataArray.filter(res => {
      return el == res.element;
     });

     uniqDates.forEach(d => {
      let filteredData2 = filteredData.filter(res => {
       return d == res.date;
      });
      uniqQueues.forEach(queue => {
       let filteredData3 = filteredData2.filter(res => {
        return queue == res.queue;
       });
       let mergedObjs = {};
       if (filteredData3.length > 0) {
        for (let index = 0; index < filteredData3.length; index++) {
         const element = filteredData3[index];
         mergedObjs = { ...mergedObjs, ...element };
        }
        dataArray.push(mergedObjs);
       }
      });
     });
    });
    let sortedDataArray = dataArray.sort((a, b) => {
     return parseInt(a.date) - parseInt(b.date);
    });
    //  this.$emit("setLoading", false);
    return sortedDataArray;
   }
  },
  async setTableDataGrouped(data) {
   if (this.pbxElements && this.pbxElements.length > 0) {
    this.$emit("setLoading", true);
    let rawDataArray = [];
    let dataArray = [];
    let dataObj = {};
    let queueReportData = data;
    let queueReportDataGrouped = queueReportData["daily"];
    if (this.selectedtime && this.selectedtime.length > 1) {
     queueReportDataGrouped = queueReportData["all"];
    }

    let queueDataKeysGrouped = Object.keys(queueReportDataGrouped);
    let pbxMapData =
     this.pbxMap && Object.keys(this.pbxMap).length > 0
      ? this.pbxMap
      : this.getPbxMap();

    queueDataKeysGrouped.forEach((key, index) => {
     let splitedKey = key.split(":");
     let queueNumber = splitedKey[2];
     let typeInfo = splitedKey[3];
     let elementNumber = splitedKey[4];
     let dataTime = splitedKey[0].length == 12 ? splitedKey[0].slice(-4) : null;
     let dataAction = splitedKey[5];
     let actionType =
      dataAction === "actions"
       ? this.actionType[splitedKey[6]]
       : dataAction === "duration" && !splitedKey[6].includes("_")
       ? [splitedKey[6]] + "_" + "duration"
       : dataAction === "duration" && splitedKey[6].includes("_")
       ? [splitedKey[6]]
       : null;
     dataObj = {};
     dataObj["queue"] = queueNumber;
     dataObj["queueName"] =
      pbxMapData &&
      pbxMapData["call_queues"] &&
      pbxMapData["call_queues"][queueNumber]
       ? pbxMapData["call_queues"][queueNumber]["name"]
       : null;
     dataObj["element"] = elementNumber;
     dataObj["elementName"] =
      typeInfo == 0 &&
      pbxMapData &&
      pbxMapData["extensions"] &&
      pbxMapData["extensions"][elementNumber]
       ? pbxMapData["extensions"][elementNumber]
       : null;
     dataObj["queueNameNumber"] =
      dataObj["queue"] +
      "-" +
      (dataObj["queueName"] ? dataObj["queueName"] : "");
     dataObj["elementNameNumber"] =
      dataObj["element"] +
      "-" +
      (dataObj["elementName"] ? dataObj["elementName"] : "");
     dataObj["time"] = dataTime;
     dataObj["keyType"] = typeInfo;
     let action =
      dataAction === "actions" || dataAction === "duration"
       ? actionType
       : dataAction;
     dataObj[action] = queueReportDataGrouped[key];
     dataObj[`${action}`] =
      dataAction >= 0
       ? queueReportDataGrouped[key]
       : queueReportDataGrouped[key];
     if (this.selectedtime && this.selectedtime.length > 1) {
      let firsQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[0].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[0].slice(-2);
      let lastQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[1].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[1].slice(-2);
      if (dataTime) {
       if (
        parseInt(firsQuarterHour) <= parseInt(dataTime) &&
        parseInt(dataTime) <= parseInt(lastQuarterHour)
       ) {
        rawDataArray.push(dataObj);
       }
      }
     } else {
      if (action && splitedKey[0].length == 8) {
       rawDataArray.push(dataObj);
      }
     }
    });
    let elements = [];
    let elementsName = [];
    let elementsNameNumber = [];
    let dates = [];
    let queues = [];
    let queuesName = [];
    let queuesNameNumber = [];
    rawDataArray.forEach(el => {
     if (el.element) {
      elements.push(el.element);
     }
     if (el.date) {
      dates.push(el.date);
     }
     if (el.queue) {
      queues.push(el.queue);
     }
     if (el.queueName) {
      queuesName.push(el.queueName);
     }
     if (el.elementName && el.keyType == 0) {
      elementsName.push(el.elementName);
     }
     if (el.element && el.elementNameNumber && el.keyType == 0) {
      elementsNameNumber.push(el.elementNameNumber);
     }
     if (el.queueNameNumber) {
      queuesNameNumber.push(el.queueNameNumber);
     }
    });
    let uniqElements = [...new Set(elements)];
    let uniqElementsName = [...new Set(elementsName)];
    let uniqElementsNameNumber = [...new Set(elementsNameNumber)];
    let uniqQueues = [...new Set(queues)];
    let uniqQueuesName = [...new Set(queuesName)];
    let uniqQueuesNameNumber = [...new Set(queuesNameNumber)];

    this.queuesArray = uniqQueues;
    this.queuesNameArray = uniqQueuesName;
    this.queuesNameNumberArray = uniqQueuesNameNumber;
    this.extensionsArray = uniqElements;
    this.extensionsNameArray = uniqElementsName;
    this.extensionsNameNumberArray = uniqElementsNameNumber;

    //     if (this.selectedtime && this.selectedtime.length > 1) {
    for (let index = 0; index < uniqElements.length; index++) {
     const uniqElement = uniqElements[index];

     for (let index = 0; index < uniqQueues.length; index++) {
      let obj = {};
      obj["waiting_duration"] = 0;
      obj["answering_duration"] = 0;
      obj["talking_duration"] = 0;
      obj["answered"] = 0;
      obj["declined_solicitations"] = 0;
      obj["transferred"] = 0;
      obj["abandoned"] = 0;
      obj["calls"] = 0;
      obj["solicited"] = 0;
      obj["dissuaded"] = 0;
      const uniqQueue = uniqQueues[index];

      rawDataArray.forEach(el => {
       let elKeys = Object.keys(el);
       if (el.element == uniqElement && el.queue == uniqQueue) {
        elKeys.forEach(key => {
         if (
          (key.includes("answering") || key.includes("talking")) &&
          !key.includes("duration")
         ) {
          obj[key] = obj[key] ? parseInt(obj[key]) : 0;
          obj[key] = parseInt(obj[key]) + parseInt(el[key]);
         }
        });
        obj["element"] = el.element;
        obj["elementName"] = el.elementName;
        obj["elementNameNumber"] = el.elementNameNumber;
        obj["queue"] = el.queue;
        obj["queueName"] = el.queueName;
        obj["queueNameNumber"] = el.queueNameNumber;
        obj["keyType"] = el.keyType;
        obj["waiting_duration"] =
         obj["waiting_duration"] +
         (el.waiting_duration ? parseInt(el.waiting_duration) : 0);
        obj["answering_duration"] =
         obj["answering_duration"] +
         (el.answering_duration ? parseInt(el.answering_duration) : 0);
        obj["talking_duration"] =
         obj["talking_duration"] +
         (el.talking_duration ? parseInt(el.talking_duration) : 0);
        obj["answered"] =
         obj["answered"] + (el.answered ? parseInt(el.answered) : 0);
        obj["declined_solicitations"] =
         obj["declined_solicitations"] +
         (el.declined_solicitations ? parseInt(el.declined_solicitations) : 0);
        obj["transferred"] =
         obj["transferred"] + (el.transferred ? parseInt(el.transferred) : 0);
        obj["abandoned"] =
         obj["abandoned"] + (el.abandoned ? parseInt(el.abandoned) : 0);
        obj["calls"] = obj["calls"] + (el.calls ? parseInt(el.calls) : 0);
        obj["solicited"] =
         obj["solicited"] + (el.solicited ? parseInt(el.solicited) : 0);
        obj["dissuaded"] =
         obj["dissuaded"] + (el.dissuaded ? parseInt(el.dissuaded) : 0);
       }
      });

      if (obj && obj.element && obj.queue) {
       let flag = true;
       for (let index = 0; index < dataArray.length; index++) {
        const elDataArray = dataArray[index];
        if (
         elDataArray.element === obj.element &&
         elDataArray.queue === obj.queue
        ) {
         flag = false;
        }
       }
       if (flag) {
        dataArray.push(obj);
       }
      }
     }
    }
    //     } else {
    //      uniqElements.forEach((el) => {
    //       let filteredData = rawDataArray.filter((res) => {
    //        return el == res.element;
    //       });

    //       uniqQueues.forEach((queue) => {
    //        let filteredData2 = filteredData.filter((res) => {
    //         return queue == res.queue;
    //        });
    //        let mergedObjs = {};
    //        if (filteredData2.length > 0) {
    //         for (let index = 0; index < filteredData2.length; index++) {
    //          const element = filteredData2[index];
    //          mergedObjs = { ...mergedObjs, ...element };
    //         }
    //         dataArray.push(mergedObjs);
    //        }
    //       });
    //      });
    //     }
    let sortedDataArray = dataArray.sort((a, b) => {
     return parseInt(a.element) - parseInt(b.element);
    });
    //  this.$emit("setLoading", false);
    return sortedDataArray;
   }
  },
  async separateQueueExtensionData(groupSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   if (this.pbxElements && this.pbxElements.length > 0) {
    this.$emit("setLoading", true);
    this.queueData = [];
    this.extensionData = [];
    // this.queueDataConsolidated = [];
    // this.extensionDataConsolidated = [];
    let dataArray = [];
    if (groupSelected && groupSelected === "grouped") {
     dataArray = this.groupData
      ? this.settedGroupTableData
      : this.settedUngroupTableData;
    } else {
     this.data = await this.getData();
     this.settedGroupTableData = await this.setTableDataGrouped(this.data);
     this.settedUngroupTableData = await this.setTableData(this.data);
     dataArray = this.groupData
      ? this.settedGroupTableData
      : this.settedUngroupTableData;
    }
    for (let index = 0; index < dataArray.length; index++) {
     const el = dataArray[index];
     if (el.queue == el.element && el.keyType == 4) {
      this.queueData.push(el);
     }
     if (el.queue == el.element || el.keyType == 0) {
      this.extensionData.push(el);
     }
    }
    if (
     this.pbxMap &&
     this.pbxMap["call_queues"] &&
     this.pbxMap["extensions"]
    ) {
     this.selectedElement = this.selectedElement.map(res => {
      return (
       res +
       (this.urlType === "queue"
        ? this.pbxMap["call_queues"][res]
          ? "-" + this.pbxMap["call_queues"][res]["name"]
          : ""
        : this.pbxMap["extensions"][res]
        ? "-" + this.pbxMap["extensions"][res]
        : "")
      );
     });
    }
    this.consolidateDataQueue(this.queueData);
    this.consolidateDataExtension(this.extensionData);
    this.$emit("setLoading", false);
   } else {
    this.queueData = [];
    this.extensionData = [];
   }
  },
  consolidateDataQueue(data) {
   let consolidatedData = [];
   let holder = {};
   let ignoreKeys = [
    "element",
    "elementName",
    "elementNameNumber",
    "keyType",
    "queue",
    "queueName",
    "queueNameNumber",
   ];
   if (data && data.length > 0) {
    data.forEach(res => {
     let keys = Object.keys(res);
     for (let index = 0; index < keys.length; index++) {
      const elementProperty = keys[index];
      if (ignoreKeys.indexOf(elementProperty) < 0) {
       if (holder.hasOwnProperty(elementProperty)) {
        holder[elementProperty] =
         parseInt(holder[elementProperty]) + parseInt(res[elementProperty]);
       } else {
        holder[elementProperty] = parseInt(res[elementProperty]);
       }
      }
     }
    });
   }
   consolidatedData.unshift(holder);
   holder["queueNameNumber"] = "Consolidée";
   this.queueDataConsolidated = consolidatedData;
  },
  consolidateDataExtension(data) {
   let duplicatedArr = [];
   let extensions = [];
   data.forEach(res => {
    if (res.keyType == 0) {
     extensions.push(res.element);
    }
   });
   let duplicatedElements = extensions.filter(
    (item, index) => extensions.indexOf(item) !== index
   );
   console.log(
    "file: ExpertStatisticsFullQueueReport.vue:846 ~ consolidatedData.forEach ~ duplicatedElements:",
    duplicatedElements
   );
   let consolidatedData = [];
   let holder = {};
   let extensionHolder = {};
   let ignoreKeys = [
    "element",
    "elementName",
    "elementNameNumber",
    "keyType",
    "queue",
    "queueName",
    "queueNameNumber",
   ];
   if (data && data.length > 0) {
    data.forEach((res, resIndex) => {
     let keys = Object.keys(res);
     if (res.keyType == 4) {
      holder["queueNameNumber"] = "Consolidée";
      holder["keyType"] = 4;
      for (let index = 0; index < keys.length; index++) {
       const elementProperty = keys[index];
       if (ignoreKeys.indexOf(elementProperty) < 0) {
        if (holder.hasOwnProperty(elementProperty)) {
         holder[elementProperty] =
          parseInt(holder[elementProperty]) + parseInt(res[elementProperty]);
        } else {
         holder[elementProperty] = parseInt(res[elementProperty]);
        }
       }
      }
      if (
       consolidatedData[0] &&
       consolidatedData[0]["keyType"] &&
       consolidatedData[0]["keyType"] != 4
      )
       consolidatedData.unshift(holder);
     } else {
      if (duplicatedElements.indexOf(res.element) < 0) {
       consolidatedData.push(res);
      } else {
       duplicatedArr.push(res);
      }
      //   extensionHolder = {};
      //   for (let index = 0; index < extensions.length; index++) {
      //    const elementExtension = extensions[index];
      //    if (elementExtension == res.element) {
      //     extensionHolder["queueNameNumber"] = "Consolidée";
      //     extensionHolder["elementNameNumber"] = res.elementNameNumber;
      //     extensionHolder["element"] = res.element;
      //     extensionHolder["keyType"] = res.keyType;
      //     for (let index = 0; index < keys.length; index++) {
      //      const elementProperty = keys[index];
      //      if (ignoreKeys.indexOf(elementProperty) < 0) {
      //       if (extensionHolder.hasOwnProperty(elementProperty)) {
      //        extensionHolder[elementProperty] = parseInt(extensionHolder[elementProperty]) + parseInt(res[elementProperty]);
      //       } else {
      //        extensionHolder[elementProperty] = parseInt(res[elementProperty]);
      //       }
      //      }
      //     }
      //     consolidatedData[index + 1] = extensionHolder;
      //    }
      //   }
     }
    });
    let arr = [];
    let uniqueDuplicatedElements = [...new Set(duplicatedElements)];
    for (
     let elementsIndex = 0;
     elementsIndex < uniqueDuplicatedElements.length;
     elementsIndex++
    ) {
     let obj = {};
     const element = uniqueDuplicatedElements[elementsIndex];
     duplicatedArr.forEach((dup, dupIdx) => {
      let keys = Object.keys(dup);
      if (element == dup.element) {
       obj["queueNameNumber"] = "Consolidée";
       obj["elementNameNumber"] = dup.elementNameNumber;
       obj["element"] = dup.element;
       obj["keyType"] = dup.keyType;
       obj["elementName"] = dup.elementName;
       for (let index = 0; index < keys.length; index++) {
        const elementProperty = keys[index];
        if (ignoreKeys.indexOf(elementProperty) < 0) {
         if (obj.hasOwnProperty(elementProperty)) {
          obj[elementProperty] =
           parseInt(obj[elementProperty]) + parseInt(dup[elementProperty]);
         } else {
          obj[elementProperty] = parseInt(dup[elementProperty]);
         }
        }
       }
      }
     });
     arr[elementsIndex] = obj;
    }
    consolidatedData = [...arr, ...consolidatedData];
   }
   this.extensionDataConsolidated = consolidatedData
    .sort((a, b) => {
     if (a.keyType == 4) {
      return -1;
     }
    })
    .sort((a, b) => {
     return parseInt(a.element) - parseInt(b.element);
    });
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
  formatTime(time) {
   let formatedTime = time.slice(0, 2) + ":" + time.slice(-2);
   return formatedTime;
  },
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  async getPbxMap() {
   if (this.hostName && this.hostName !== "null") {
    try {
     const res = await axios.get(
      `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/map`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
       },
      }
     );
     this.$store.dispatch("pbxMap", res.data);
     localStorage.setItem("pbxMap", JSON.stringify(res.data));

     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedDataExtension() {
   let extensionData = this.concatenateData
    ? this.extensionDataConsolidated
    : this.extensionData;
  
   return extensionData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = a.keyType == 0 && b.keyType == 0 ? 1 : -1;
    if (this.currentSortDir === "desc")
     modifier = a.keyType == 0 && b.keyType == 0 ? -1 : 1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
  sortedDataQueue() {
   let queueData = this.concatenateData ? this.queueDataConsolidated : this.queueData;
   return queueData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
 },
 watch: {
  selectedtime: function (val) {
   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   this.separateQueueExtensionData();
   if (flag) this.concatenateData = true;
  },
  period: function (val) {
   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   this.separateQueueExtensionData();
   if (flag) this.concatenateData = true;
  },
  groupData: function (val) {
   let grouped = "grouped";
   this.separateQueueExtensionData(grouped);
  },
  searchQueue: function (val) {
   if (val && val.length <= 0) {
    this.searchQueue = null;
   }
  },
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
  },
  concatenateData: function (val) {
   if (val) this.groupData = true;
  },
 },
 mounted() {
  //   this.getPbxMap();
  if (!this.period && !this.pbxPeriodSelected) {
   this.period = this.currentMonthAndYearForPbx();
  }
  if (this.pbxPeriodSelected) this.period = this.pbxPeriodSelected;
  //   this.currentMonthAndYearForPbx();

  if (this.pbxElements) {
   this.selectedElement = this.pbxElements;

   if (this.pbxElementType !== this.urlType)
    this.$store.dispatch("pbxElements", null);
  }
 },
 computed: {
  filteredQueueData() {
   //  let queueData = this.concatenateData
   //   ? this.queueDataConsolidated
   //   : this.queueData;
   return this.sortedDataQueue().filter(data => {
    if (this.searchQueue) {
     let flag = false;
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else {
     return data;
    }
   });
  },
  filteredExtensionData() {
   return this.sortedExtensionData.filter(data => {
    if (this.searchQueue && !this.searchExtension) {
     let flag = false;
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else if (!this.searchQueue && this.searchExtension) {
     let flag = false;
     this.searchExtension.forEach(element => {
      if (
       element.includes(data.element) &&
       element.includes(data.elementName)
      ) {
       flag = true;
      }
     });
     return flag;
    } else if (this.searchQueue && this.searchExtension) {
     let flag = false;
     let flagQueue = false;
     let flagExtension = false;
     this.searchExtension.forEach(element => {
      if (
       element.includes(data.element) &&
       element.includes(data.elementName)
      ) {
       flagExtension = true;
      }
     });
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flagQueue = true;
      }
     });
     if (flagExtension && flagQueue) {
      flag = true;
     }
     return flag;
    } else {
     return data;
    }
   });
  },
  sortedExtensionData() {
   //  let extensionData = this.concatenateData
   //   ? this.extensionDataConsolidated
   //   : this.extensionData;
   let firstSort = this.sortedDataExtension().sort((a, b) => {
    if (a.keyType == 4) {
     return -1;
    }
   });

   return firstSort.sort((a, b) => {
    if (a.keyType == 4) {
     return parseInt(a.date) - parseInt(b.date);
    }
   });
  },
  ...mapGetters([
   "hostName",
   "hostTimeZone",
   "pbxPeriodSelected",
   "pbxElements",
   "pbxMap",
   "pbxElementType",
  ]),
 },
};
</script>

<style>
.switch-group {
 position: absolute;
 right: 1.3rem;
 top: 23.5rem;
}
.switch-group-no-info {
 position: absolute;
 right: 1.3rem;
 top: 18.5rem;
}
</style>
